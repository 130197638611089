<template>
  <div class="recursion" ref="canvas" />
</template>

<script>
import loadImage from "@/js/loadImage";
export default {
  data: function () {
    return {
      isMounted: false,
    };
  },
  methods: {
    init() {
      // A function to recusively draw interpolated squares up to max depth
      var interpolate = (pts, _t, depth, max) => {
        if (!this.isMounted) return;
        if (depth > max) return;
        let g = new Group();
        let t = Num.boundValue(_t, 0, 1);

        for (let i = 1, len = pts.length; i < len; i++) {
          g.push(Geom.interpolate(pts[i - 1], pts[i], t));
        }

        const coord = Geom.interpolate(pts[pts.length - 1], pts[0], t);

        g.push(coord);

        // const test = form
        //   .strokeOnly(depth % 2 === 0 ? "black" : "black")
        //   .polygon(g);

        const image2 = form.image([...coord, 120, 120], this.img);
        //image2.resize(coord);

        interpolate(g, t + 0.00002, depth + 1, max);
      };

      // Pts quick start mode.
      var run = Pts.quickStart(this.$refs.canvas, "transparent");
      run((time, ftime) => {
        let size = space.size.$multiply(2).minValue().value;

        let rect = Rectangle.corners([
          space.center.$subtract(size),
          space.center.$add(size),
        ]);
        let t = space.pointer.x / space.size.x + (time % 10000) / 400000;

        interpolate(rect, t, 0, 200);
      });
    },
  },
  async mounted() {
    // Source code licensed under Apache License 2.0.
    // Copyright © 2017 William Ngan. (https://github.com/williamngan/pts)
    const src = require("@/assets/emojis/brock-recursion-brock-recursion-brock.png");
    this.img = await loadImage(src);
    this.isMounted = true;
    this.init();
  },
  beforeUnmount() {
    this.isMounted = false;
  },
};
</script>

<style scoped>
.recursion {
  width: 100vw;
  height: 100vh;
}
</style>
