<template>
  <div class="home">
    <div class="grid">
      <div
        class="emoji"
        v-for="e in emojis"
        :key="e.name"
        @click="$emit('select', e)"
      >
        <img :src="require(`@/assets/emojis/${e.src}`)" />
      </div>
    </div>
    <action-bar above>
      <template v-slot:right>
        <span @click="$emit('close')">Close</span>
      </template>
    </action-bar>
  </div>
</template>

<script>
import ActionBar from "@/components/ActionBar";

import emojis from "@/assets/emojis.json";
export default {
  name: "EmojiPicker",
  components: {
    ActionBar,
  },
  data() {
    return {
      emojis,
      emoji: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(20, calc(100vw / 20));
  grid-template-rows: repeat(20, calc(100vw / 20));
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: white;
  overflow: auto;

  .emoji {
    aspect-ratio: 1/1;
    //border: 0.005rem solid;
    padding: 20%;

    &:hover {
      outline: 3px solid;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      image-rendering: pixelated;
    }
  }
}
</style>
