import Matter from "matter-js";

export default function ({
  element,
  width = window.innerWidth,
  height = window.innerHeight,
}) {
  var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Bodies = Matter.Bodies;

  // create engine
  var engine = Engine.create(),
    world = engine.world;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      showAngleIndicator: false,
      wireframes: false,
      background: "black",
    },
  });

  Render.setPixelRatio(render, "auto");

  Render.run(render);

  // create runner
  var runner = Runner.create();
  Runner.run(runner, engine);

  // add bodies
  var offset = 10,
    options = {
      isStatic: true,
      render: {
        visible: false,
      },
    };

  world.bodies = [];

  // these static walls will not be rendered in this sprites example, see options
  Composite.add(world, [
    Bodies.rectangle(width / 2, -offset, width + 2 * offset, 50.5, options),
    Bodies.rectangle(
      width / 2,
      height + offset,
      width + 2 * offset,
      50.5,
      options
    ),
    Bodies.rectangle(
      width + offset,
      height / 2,
      50.5,
      height + 2 * offset,
      options
    ),
    Bodies.rectangle(-offset, height / 2, 50.5, height + 2 * offset, options),
  ]);

  var stack = Composites.stack(20, 20, 40, 40, 0, 0, function (x, y) {
    // if (Common.random() > 0.35) {
    //   return Bodies.rectangle(x, y, 64, 64, {
    //     render: {
    //       strokeStyle: "#ffffff",
    //       sprite: {
    //         texture: require("@/assets/tiny-tommy-tomato.png"),
    //       },
    //     },
    //   });
    // } else {
    return Bodies.circle(x, y, 46, {
      density: 0.0005,
      frictionAir: 0.06,
      restitution: 0.3,
      friction: 0.01,
      render: {
        sprite: {
          texture: require("@/assets/tommy-tomato.png"),
        },
      },
    });
    // }
  });

  const tomato = Bodies.circle(100, 100, 200, {
    density: 0.0005,
    frictionAir: 0.06,
    restitution: 0.3,
    friction: 0.01,
    render: {
      sprite: {
        texture: require("@/assets/tiny-tommy-tomato.png"),
      },
    },
  });

  Composite.add(world, stack);
  setTimeout(() => {
    Composite.add(world, tomato);
  }, 2000);

  // add mouse control
  var mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

  Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });

  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
  };
}
