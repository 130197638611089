<template>
  <div @mousemove="mousemove" />
</template>

<script>
import Matter from "matter-js";
import { scaleLinear } from "d3-scale";

const sketch = function ({
  element,
  width = window.innerWidth,
  height = window.innerHeight,
}) {
  var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Bodies = Matter.Bodies;

  // create engine
  var engine = Engine.create(),
    world = engine.world;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      showAngleIndicator: true,
      wireframes: false,
      background: "white",
    },
  });

  Render.setPixelRatio(render, "auto");

  Render.run(render);

  // create runner
  var runner = Runner.create();
  Runner.run(runner, engine);

  // add bodies
  var offset = -20,
    options = {
      isStatic: true,
      render: {
        visible: false,
      },
    };

  world.bodies = [];

  // these static walls will not be rendered in this sprites example, see options
  Composite.add(world, [
    Bodies.rectangle(width / 2, -offset, width + 2 * offset, 50.5, options),
    Bodies.rectangle(
      width / 2,
      height + offset,
      width + 2 * (offset / 4),
      50.5,
      options
    ),
    Bodies.rectangle(
      width + offset / 4,
      height / 2,
      50.5,
      height + 2 * offset,
      options
    ),
    Bodies.rectangle(
      -offset / 4,
      height / 2,
      50.5,
      height + 2 * offset,
      options
    ),
  ]);

  var stack = Composites.stack(50, 50, 90, 6, -10, -10, function (x, y) {
    // if (Common.random() > 0.35) {
    //   return Bodies.rectangle(x, y, 64, 64, {
    //     render: {
    //       strokeStyle: "#ffffff",
    //       sprite: {
    //         texture: require("@/assets/tiny-tommy-tomato.png"),
    //       },
    //     },
    //   });
    // } else {

    const random = (min, max) =>
      Math.floor(Math.random() * (max - min + 1) + min);

    const fries = [
      { img: require("@/assets/img/fries-1.png"), width: 10, height: 150 },
      { img: require("@/assets/img/fries-4.png"), width: 17, height: 150 },
      { img: require("@/assets/img/fries-5.png"), width: 10, height: 150 },
    ];

    const n = random(0, fries.length - 1);
    const potato = fries[n];

    return Bodies.rectangle(x, y, potato.width, potato.height, {
      density: 0.0505,
      frictionAir: 0.02,
      restitution: 0.5,
      friction: 0.01,
      render: {
        sprite: {
          texture: potato.img,
          xScale: 0.25,
          yScale: 0.25,
        },
      },
    });
    // }
  });

  const tomato = Bodies.circle(window.innerWidth / 2 - 100, 100, 60, {
    density: 0.0905,
    frictionAir: 0.01,
    restitution: 0.5,
    friction: 0.01,
    render: {
      sprite: {
        texture: require("@/assets/emojis/dever-christiania-chariot.gif"),
      },
    },
  });

  Composite.add(world, stack);
  setTimeout(() => {
    Composite.add(world, tomato);
  }, 1000);

  // add mouse control
  var mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

  Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });

  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
  };
};

export default {
  mounted() {
    this.sketch = sketch({
      element: this.$el,
      width: this.width,
      height: this.height,
    });
  },
  methods: {
    mousemove(e) {
      const scaleX = scaleLinear()
        .domain([0, window.innerWidth])
        .range([-1, 1]);
      const scaleY = scaleLinear()
        .domain([0, window.innerHeight])
        .range([-1, 1]);

      this.sketch.engine.world.gravity.x = scaleX(e.clientX);
      this.sketch.engine.world.gravity.y = scaleY(e.clientY);
    },
  },
  unmounted() {
    this.sketch.stop();
  },
};
</script>

<style scoped></style>
