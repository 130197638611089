<template>
  <div class="vh">
    <img
      ref="img"
      width="28"
      :src="require('@/assets/emojis/tiny-rebecca.png')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    loop() {
      const random = (min, max) =>
        Math.floor(Math.random() * (max - min + 1) + min);
      const maxX = window.innerWidth - 28;
      const maxY = window.innerHeight - 43 - 28;

      const x = random(0, maxX);
      const y = random(0, maxY);

      this.$refs.img.style.transform = `translate(${x}px, ${y}px)`;
    },
  },
  mounted() {
    this.loop();

    this.interval = setInterval(this.loop, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>
