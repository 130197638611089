<template>
  <div
    class="intro"
    :class="{ ready: ready || !init, hand: !init }"
    @click="next"
    :style="{ opacity: init ? 1 : 0 }"
    @mouseover="onMousemove"
    @mousemove="onMousemove"
  >
    <video
      :src="video"
      preload
      @ended="onEnded"
      ref="video"
      v-show="!looping"
    />
    <video :src="loop" preload muted loop ref="loop" v-show="looping" />
    <Ident :lines="lines" ref="ident" />
    <footer v-if="ready">Tap to get started</footer>
  </div>
  <img
    :src="cursor"
    class="cursor"
    v-if="!init"
    :style="{ transform: `translate3d(${x}px, ${y}px, 0px)` }"
  />
</template>

<script>
import gsap from "gsap";
import Ident from "@/components/Ident";

import Preload from "preload-it";

export default {
  components: {
    Ident,
  },
  data() {
    return {
      preloaded: false,
      looping: false,
      init: false,
      ready: false,
      x: window.innerWidth / 2 - 32,
      y: window.innerHeight / 2 - 32,
      video: require("@/assets/video/emo-3.mp4"),
      loop: require("@/assets/video/loop.mp4"),
      lines: [
        { text: "Emotional", x: "center", y: "top", fill: true },
        { text: "Church", x: "center", y: "bottom", fill: true },
      ],
    };
  },
  methods: {
    onMousemove(e) {
      if (this.init || !this.preloaded) return;
      this.x = e.clientX;
      this.y = e.clientY;
    },
    onEnded() {
      this.looping = true;
      this.$refs.loop.play();
    },
    next() {
      if (!this.preloaded) return;
      if (!this.init) {
        this.init = true;
        this.start();
        return false;
      }
      if (!this.ready) return;
      this.$router.push({ name: "Single", params: { id: 0 } });
    },
    start() {
      const el = this.$refs.ident.$el;
      const lines = el.querySelectorAll(".line");

      this.$refs.video.play();

      const timeline = gsap.timeline();

      console.log(lines);

      timeline.set(lines[0], { opacity: 1 }, "+=18");
      timeline.set(lines[1], { opacity: 1 }, "+=1");

      timeline.set(
        el,
        {
          opacity: 0,
          onComplete: () => {
            this.ready = true;
          },
        },
        "+=1.5"
      );
    },
  },
  computed: {
    cursor() {
      const loading = require("@/assets/emojis/one-hand-many-stories.gif");
      const done = require("@/assets/emojis/rebecca-hand-second-generation.png");

      return this.preloaded ? done : loading;
    },
  },
  async created() {
    const preload = Preload();

    const items = await preload.fetch([
      require("@/assets/video/emo-3.mp4"),
      require("@/assets/video/loop.mp4"),
      require("@/assets/tiny-tommy-tomato.png"),
      require("@/assets/tommy-tomato.png"),
    ]);

    console.log("ready", items);
    setTimeout(() => {
      this.preloaded = true;
    }, 1000);
  },
  mounted() {
    const el = this.$refs.ident.$el;
    const lines = el.querySelectorAll(".line");

    gsap.set(lines, { opacity: 0 });
    gsap.set(el, { color: "white" });
  },
};
</script>

<style lang="scss" scoped>
.intro {
  cursor: none;
  height: 100vh;
  &.ready {
    cursor: inherit;
  }

  &.hand {
    cursor: none;
  }
}
video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  left: 0;
  top: 0;
}
footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: var(--gutter-y) var(--gutter-x);
  color: white;
  text-align: center;
}
.cursor {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>
