<template>
  <div class="edp" @click="$emit('close')">
    <img class="emoji" :src="require(`@/assets/emojis/${e.src}`)" />
    <footer>
      <label>{{ e.name }}</label>
      <img :src="author.avatar" />
    </footer>
  </div>
</template>

<script>
import emojis from "@/assets/emojis.json";

import { mapGetters } from "vuex";

export default {
  name: "Edp",
  props: {
    emoji: String,
  },
  data() {
    return {
      emojis,
    };
  },
  computed: {
    ...mapGetters(["getAuthor"]),
    e() {
      return this.emojis.find((emoji) => emoji.name === this.emoji);
    },
    author() {
      return this.getAuthor(this.e.user_display_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.edp {
  display: grid;
  place-items: center;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  img.emoji {
    display: block;
    height: calc(100vh - (var(--bar-height) * 2));
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    aspect-ratio: 1/1;
  }
}
footer {
  width: 100%;
  padding: 0px var(--gutter-x);
  height: var(--bar-height);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  img {
    display: block;
    width: calc(var(--bar-height) - 8px);
    height: calc(var(--bar-height) - 8px);
    object-fit: cover;
  }
}
</style>
