<template>
  <router-view />
</template>

<style src="./css/reset.css" />
<style src="./css/variables.css" />
<style src="./css/inputs.css" />
<style src="./css/themes.css" />
<style src="./css/helpers.css" />
<style src="./css/transitions.css" />
<style src="./css/typography.css" />

<script>
export default {
  mounted() {
    // window.addEventListener("resize", () => {
    //   location.reload();
    // });
  },
};
</script>

<style lang="scss">
html {
  cursor: url(./assets/img/cursor.svg), auto;
}
body {
  overscroll-behavior: none;
  overflow: hidden;
  user-select: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vh {
  height: 100vh;
}
</style>
