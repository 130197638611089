<template>
  <div class="ident" :class="{ columns: columns }">
    <h1
      class="line"
      v-for="(line, n) in lines"
      :key="n"
      :class="setClasses(line)"
    >
      {{ line.text }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    columns: Boolean,
    lines: {
      type: Array,
      default() {
        return [
          { text: "ELP", x: "left", y: "top", fill: true },
          { text: "(Emo landing", x: "center", y: "bottom", fill: true },
          { text: "page)", x: "left", y: "bottom", fill: false },
        ];
      },
    },
  },
  methods: {
    setClasses(style) {
      return {
        [`x-${style.x}`]: style.x,
        [`y-${style.y}`]: style.y,
        fill: style.fill,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ident {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: calc(var(--font-size-responsive) * 0.25);
  cursor: none;
  position: absolute;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  .line {
    width: 100%;
    display: flex;

    &.x {
      &-left {
        text-align: left;
        justify-content: flex-start;
      }
      &-center {
        text-align: center;
        justify-content: center;
      }
      &-right {
        text-align: right;
        justify-content: flex-end;
      }
    }
    &.y {
      &-top {
        align-items: flex-start;
      }
      &-center {
        align-items: center;
      }
      &-bottom {
        align-items: flex-end;
      }
    }
    &.fill {
      flex: 1;
    }
  }

  &.columns {
    flex-direction: row;
  }
}
</style>
