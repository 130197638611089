<template>
  <frame-viewer v-bind="frame" />
</template>

<script>
import FrameViewer from "@/components/FrameViewer";

export default {
  components: {
    FrameViewer,
  },
  data() {
    return {
      frame: {
        grid: "6",
        cells: [
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "lisa-unserious-business.png",
              name: "lisa-unserious-business",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: { src: "no-jam.png", name: "no-jam" } },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: { src: "blank-space.png", name: "blank-space" } },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "carola-listening-to-a-captivating-story-part-1.png",
              name: "carola-listening-to-a-captivating-story-part-1",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "rebecca-hand-second-generation.png",
              name: "rebecca-hand-second-generation",
            },
          },
          { emoji: null },
          { emoji: null },
        ],
      },
    };
  },
};
</script>
