<template>
  <div
    style="
      width: 100vw;
      height: 100vh;
      display: grid;
      place-items: center;
      background: black;
    "
  >
    <canvas ref="canvas" />
  </div>
</template>

<script>
import Pong from "./game";

export default {
  mounted() {
    this.pong = new Pong(this.$refs.canvas);
    //Pong.initialize(this.$refs.canvas);
  },
  beforeUnmount() {
    this.pong.stop();
  },
};
</script>

<style scoped></style>
