import gsap from "gsap";
import Matter from "matter-js";

export default function ({
  element,
  width = window.innerWidth,
  height = window.innerHeight,
}) {
  var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Bodies = Matter.Bodies;

  // create engine
  var engine = Engine.create(),
    world = engine.world;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      showAngleIndicator: false,
      wireframes: false,
      background: "transparent",
    },
  });

  Render.run(render);

  const tween = {
    gravity: 1,
  };

  world.gravity.x = -0.05;
  world.gravity.y = -0.5;

  // gsap.to(tween, {
  //   gravity: -1,
  //   duration: 2,
  //   onUpdate: () => {
  //     world.gravity.y = tween.gravity;
  //   },
  //   delay: 0,
  // });

  // create runner
  var runner = Runner.create();
  Runner.run(runner, engine);

  // add bodies
  var offset = 10,
    options = {
      isStatic: true,
      render: {
        visible: false,
      },
    };

  world.bodies = [];

  // these static walls will not be rendered in this sprites example, see options
  Composite.add(world, [
    Bodies.rectangle(
      width / 2,
      offset * 10,
      width + 2.5 * offset,
      50.5,
      options
    ),
    Bodies.rectangle(
      width / 2,
      height - offset * 20,
      width + 2.5 * offset,
      50.5,
      options
    ),
    Bodies.rectangle(
      width + offset,
      height / 2,
      50.5,
      height + 2.5 * offset,
      options
    ),
    Bodies.rectangle(-offset, height / 2, 50.5, height + 2.5 * offset, options),
  ]);

  var stack = Composites.stack(40, 40, 1, 1, 0, 0, function (x, y) {
    return Bodies.rectangle(
      window.innerWidth / 2,
      window.innerHeight / 2,
      220,
      220,
      {
        density: 0.9009,
        frictionAir: 0.026,
        restitution: 0.1,
        friction: 0.01,
        render: {
          sprite: {
            texture: require("@/assets/emojis/louise-up.png"),
            xScale: 0.75,
            yScale: 0.75,
          },
        },
      }
    );
  });

  Composite.add(world, stack);

  // add mouse control
  var mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

  Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });
  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
  };
}
