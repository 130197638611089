<template>
  <div class="calvin">
    <div ref="canvas" />
    <action-bar above theme="transparent-on-light">
      <div id="calvin"></div>
    </action-bar>
  </div>
</template>

<script>
import ActionBar from "@/components/ActionBar";
import p5 from "p5";
import "p5/lib/addons/p5.sound";

const s = (p) => {
  let music;
  let button;
  let fft;
  let totalAmp;
  let playButton;
  let barCount;
  let barWidth;
  let bgColor;
  let checkBox = [];
  let angleScale = 200;
  let image;
  p.preload = () => {
    image = p.loadImage(require("@/assets/img/calvin-guitar.png"));
    music = p.loadSound(require("@/assets/mp3/faith-short.mp3"));
  };

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    // Variable settings
    p.colorMode(p.RGB);
    p.angleMode(p.DEGREES);

    // Create Play Button
    playButton = p.createButton("Play");
    playButton.mousePressed(togglePlayButton);

    playButton.parent("calvin");

    // Total Sound Amplitude for background color change
    totalAmp = new p5.Amplitude();

    barCount = 256;
    fft = new p5.FFT(0.7, barCount); // (smoothing, number of divisions for frequencies)

    barWidth = p.windowWidth / barCount;
  };
  p.draw = () => {
    // FFT vis
    drawSpectrum();
    // Waveform vis
    //drawWaveform();
    // Init image
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };

  function drawSpectrum() {
    // Initialize
    let spectrum = fft.analyze();
    p.translate(p.windowWidth / 2, p.windowHeight / 2);

    // Background
    let sumAmp = 0;
    for (let i = 0; i < spectrum.length; i++) {
      let amplitude = spectrum[i];
      sumAmp += amplitude;
    }
    let avgAmp = sumAmp / spectrum.length;
    bgColor = p.map(avgAmp, 0, 255, 0, 50);

    p.background("#fff");

    // Spectrum
    for (let i = 24; i < spectrum.length - 20; i++) {
      let angle = p.map(i, 0, spectrum.length, 0, angleScale);
      let amplitude = spectrum[i];
      let smallestWinDim;
      if (p.windowHeight < p.windowWidth) {
        smallestWinDim = p.windowHeight;
      } else {
        smallestWinDim = p.windowWidth;
      }
      let r = p.map(amplitude, 0, 256, 90, (smallestWinDim / 2) * 0.55);
      let x = r * p.cos(-angle);
      let y = r * p.sin(-angle);
      p.stroke("black"); // Color of spectrum

      p.strokeWeight(2.5);

      p.line(0, 0, x, y);
    }
    p.fill(255, 255, 255);
    p.noStroke();
    p.circle(0, 0, 200);
    p.imageMode(p.CENTER);

    let rms = totalAmp.getLevel();

    let size = 300 + 100 * rms;

    p.image(image, 0, 0, size, size);
  }

  function drawWaveform() {
    let waveform = fft.waveform();
    p.beginShape();
    p.noFill();
    p.stroke(50, 255, 255);
    p.strokeWeight(40);
    for (let i = 0; i < waveform.length; i++) {
      let amplitude = waveform[i];
      let angle = p.map(i, 0, waveform.length, 0, 361);
      let r = p.map(amplitude, 0, 256, 200, (p.windowWidth / 2) * 0.9);
      let lineHeight = p.map(amplitude, -1, 1, r / 4, r / 2);
      let x = (r / 2 + lineHeight) * p.cos(angle + p.frameCount / 2);
      let y = (r / 2 + lineHeight) * p.sin(angle + p.frameCount / 2);
      p.vertex(x, y);
      // p.imageMode(p.CENTER);
      // p.image(image, x, y, 270, 270);
    }

    p.endShape(p.CLOSE);
  }

  // Play/Pause button
  function togglePlayButton() {
    if (!music.isPlaying()) {
      music.play();
      playButton.html("Pause");
    } else {
      music.pause();
      playButton.html("Play");
    }
  }
};

export default {
  components: {
    ActionBar,
  },
  mounted() {
    this.P5 = new p5(s, this.$refs.canvas);
  },
  methods: {
    play() {
      console.log(this.P5, this.P5.togglePlayButton);
    },
  },
  beforeUnmount() {
    this.P5.remove();
  },
};
</script>
