<template>
  <footer :class="{ [theme]: theme }" :style="{ zIndex: above ? 1 : null }">
    <aside>
      <slot name="left" />
    </aside>
    <aside>
      <slot />
    </aside>
    <aside>
      <slot name="right" />
    </aside>
  </footer>
</template>

<script>
export default {
  props: {
    theme: String,
    above: Boolean,
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  padding: 0 var(--gutter-x);
  height: var(--bar-height);
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;

  &.transparent-on-dark {
    background: transparent;
    color: white;
  }
  &.transparent-on-light {
    background: transparent;
    color: black;
  }

  aside {
    flex: 1;
    display: flex;
    gap: var(--gap);
    &:nth-child(2) {
      justify-content: center;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
}
</style>
