<template>
  <div />
</template>

<script>
import p5 from "p5";

const s = (p) => {
  // Bouncing DVD Logo
  // Daniel Shiffman
  // https://thecodingtrain.com/CodingChallenges/131-bouncing-dvd-logo.html
  // https://youtu.be/0j86zuqqTlQ

  let x;
  let y;

  let xspeed;
  let yspeed;

  let unas;
  let unaIndex = 0;
  let una;

  let r, g, b;

  p.preload = () => {
    unas = [
      p.loadImage(require("@/assets/emojis/una.png")),
      p.loadImage(require("@/assets/emojis/una-happy.png")),
      p.loadImage(require("@/assets/emojis/una-deal-with-it.jpg")),
      p.loadImage(require("@/assets/emojis/una-upset.png")),
      p.loadImage(require("@/assets/emojis/una-upside-down.png")),
      p.loadImage(require("@/assets/emojis/unas-slightly-smiling-plate.jpg")),
    ];

    una = unas[unaIndex];
  };

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    //p.canvas.parent(this.$el);
    x = p.random(p.width);
    y = p.random(p.height - 43);
    xspeed = 5;
    yspeed = 5;
    p.pickColor();
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };

  p.pickColor = () => {
    if (unaIndex === unas.length - 1) unaIndex = -1;
    unaIndex++;
    una = unas[unaIndex];
    // r = p.random(100, 256);
    // g = p.random(100, 256);
    // b = p.random(100, 256);
  };

  p.draw = () => {
    p.background(0);
    // rect(x, y, 80, 60);
    // Draw the DVD logo
    //p.tint(r, g, b);
    p.image(una, x, y);

    x = x + xspeed;
    y = y + yspeed;

    if (x + una.width >= p.width) {
      xspeed = -xspeed;
      x = p.width - una.width;
      p.pickColor();
    } else if (x <= 0) {
      xspeed = -xspeed;
      x = 0;
      p.pickColor();
    }

    if (y + una.height >= p.height - 43) {
      yspeed = -yspeed;
      y = p.height - 43 - una.height;
      p.pickColor();
    } else if (y <= 0) {
      yspeed = -yspeed;
      y = 0;
      p.pickColor();
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };
};

export default {
  mounted() {
    this.P5 = new p5(s, this.$el);
  },
  beforeUnmount() {
    this.P5.remove();
  },
};
</script>

<style scoped></style>
