<template>
  <div class="home">
    <div class="grid">
      <div
        class="emoji"
        v-for="e in filteredEmojis"
        :key="e.name"
        @click="showEmoji(e.name)"
      >
        <img
          :src="require(`@/assets/emojis/${e.src}`)"
          :style2="{
            transform: `rotate(${Math.random() * -100}deg) scale(${
              0.5 + Math.random() * 0.9
            })`,
          }"
        />
      </div>
    </div>
    <EDP v-if="emoji" :emoji="emoji" @close="onClose" />
  </div>
</template>

<script>
import EDP from "@/views/Singles/ELP/EDP";

const getShuffledArr = (arr) => {
  if (arr.length === 1) {
    return arr;
  }
  const rand = Math.floor(Math.random() * arr.length);
  return [arr[rand], ...getShuffledArr(arr.filter((_, i) => i != rand))];
};

import emojis from "@/assets/emojis.json";
export default {
  name: "Home",
  components: {
    EDP,
  },
  data() {
    return {
      emojis,
      emoji: null,
    };
  },
  methods: {
    showEmoji(name) {
      this.emoji = name;
      this.$emit("showFooter", false);
    },
    onClose() {
      this.emoji = null;
      this.$emit("showFooter", true);
      console.log("true");
    },
  },
  computed: {
    creators() {
      const authors = this.emojis.map((emoji) => emoji.user_display_name);

      return [...new Set(authors)];
    },
    filteredEmojis() {
      const repeat = 7;
      const array = [];

      for (let i = 0; i < repeat; i++) {
        array.push(...getShuffledArr(this.emojis));
      }

      return array.filter((emoji) => true);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  width: 100%;

  .emoji {
    aspect-ratio: 1/1;
    //border: 0.005rem solid;
    padding: 20%;

    &:hover {
      outline: 3px solid;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      image-rendering: pixelated;
    }
  }
}
</style>
