<template>
  <div />
</template>

<script>
import p5 from "p5";

const s = (p) => {
  var Node = function (x, y, minX, maxX, minY, maxY) {
    p5.Vector.call(this, x, y, 0);
    this.minX = Number.MIN_VALUE || minX;
    this.maxX = Number.MAX_VALUE || maxX;
    this.minY = Number.MIN_VALUE || minY;
    this.maxY = Number.MAX_VALUE || maxY;
    this.radius = 200; // Radius of impact
    this.ramp = 1; // Influences the shape of the function
    this.strength = -1; // Strength: positive value attracts, negative value repels
    this.damping = 0.5;
    this.velocity = p.createVector();
    this.pVelocity = p.createVector();
    this.maxVelocity = 10;
  };

  Node.prototype = Object.create(p5.Vector.prototype);

  Node.prototype.attractNodes = function (nodeArray) {
    for (var i = 0; i < nodeArray.length; i++) {
      var otherNode = nodeArray[i];
      // Stop when empty
      if (otherNode === undefined) break;
      // Continue from the top when node is itself
      if (otherNode === this) continue;

      this.attract(otherNode);
    }
  };

  Node.prototype.attract = function (otherNode) {
    var thisNodeVector = p.createVector(this.x, this.y);
    var otherNodeVector = p.createVector(otherNode.x, otherNode.y);
    var d = thisNodeVector.dist(otherNodeVector);

    if (d > 0 && d < this.radius) {
      var s = p.pow(d / this.radius, 1 / this.ramp);
      var f = (s * 9 * this.strength * (1 / (s + 1) + (s - 3) / 4)) / d;
      var df = thisNodeVector.sub(otherNodeVector);
      df.mult(f);

      otherNode.velocity.x += df.x;
      otherNode.velocity.y += df.y;
    }
  };

  Node.prototype.update = function () {
    this.velocity.limit(this.maxVelocity);

    this.x += this.velocity.x;
    this.y += this.velocity.y;

    if (this.x < this.minX) {
      this.x = this.minX - (this.x - this.minX);
      this.velocity.x = -this.velocity.x;
    }
    if (this.x > this.maxX) {
      this.x = this.maxX - (this.x - this.maxX);
      this.velocity.x = -this.velocity.x;
    }

    if (this.y < this.minY) {
      this.y = this.minY - (this.y - this.minY);
      this.velocity.y = -this.velocity.y;
    }
    if (this.y > this.maxY) {
      this.y = this.maxY - (this.y - this.maxY);
      this.velocity.y = -this.velocity.y;
    }

    this.velocity.mult(1 - this.damping);
  };

  Node.prototype.constructor = Node;

  // An array with nodes
  var nodes = [];
  var johans;
  let r;
  let g;
  let b;

  let timer = 0;

  var nodeCount = 100;

  p.preload = () => {
    johans = [
      p.loadImage(require("@/assets/emojis/johan-bop.gif")),
      p.loadImage(require("@/assets/emojis/johan-happy-place.png")),
      p.loadImage(require("@/assets/emojis/johan-happy-magician.png")),
      p.loadImage(require("@/assets/emojis/una-upset.png")),
      p.loadImage(require("@/assets/emojis/una-upside-down.png")),
      p.loadImage(require("@/assets/emojis/unas-slightly-smiling-plate.jpg")),
    ];
  };

  p.setup = function () {
    p.imageMode(p.CENTER);
    p.createCanvas(p.windowWidth, p.windowHeight);
    p.noStroke();

    // Create nodes
    createNodes();
    randomColor();
  };

  function randomColor() {
    r = p.random(255);
    g = p.random(255);
    b = p.random(255);
  }

  p.draw = function () {
    if (p.millis() < 1000) return;
    if (p.millis() >= 450 + timer) {
      randomColor();
      timer = p.millis();
    }

    p.fill(r, g, b);

    p.rect(0, 0, p.width, p.height);

    p.fill(0);
    for (var i = 0; i < nodes.length; i++) {
      // Let all nodes repel each other
      nodes[i].attractNodes(nodes);
      // Apply velocity vector and update position
      nodes[i].update();
      // Draw node
      //p.ellipse(nodes[i].x, nodes[i].y, 50, 50);
      p.image(johans[0], nodes[i].x, nodes[i].y, 50, 50);
    }
  };

  p.keyPressed = function () {
    if (p.key == "s" || p.key == "S") p.saveCanvas(gd.timestamp(), "png");
    if (p.key == "r" || p.key == "R") {
      p.background(255);
      createNodes();
    }
  };
  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };

  function createNodes() {
    nodes = [];
    for (var i = 0; i < nodeCount; i++) {
      nodes.push(
        new Node(
          p.width / 2 + p.random(-1, 1),
          p.height / 2 + p.random(-1, 1),
          5,
          p.width - 5,
          5,
          p.height - 5
        )
      );
    }
  }
};

export default {
  mounted() {
    this.P5 = new p5(s, this.$el);
  },
  beforeUnmount() {
    this.P5.remove();
  },
};
</script>
