<template>
  <frame-viewer v-bind="frame" />
</template>

<script>
import FrameViewer from "@/components/FrameViewer";

export default {
  components: {
    FrameViewer,
  },
  data() {
    return {
      frame: {
        grid: "17",
        cells: [
          { emoji: { src: "blessed-top-left.png", name: "blessed-top-left" } },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: {
              src: "blessed-top-center.png",
              name: "blessed-top-center",
            },
          },
          {
            emoji: { src: "blessed-top-right.png", name: "blessed-top-right" },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: { src: "rebeccha-nd.png", name: "rebeccha-nd" } },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: { src: "labre-larve.png", name: "labre-larve" } },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: { src: "tiny-rebecca.png", name: "tiny-rebecca" } },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-center-left.png",
              name: "blessed-center-left",
            },
          },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          { emoji: null },
          {
            emoji: {
              src: "blessed-center-right.png",
              name: "blessed-center-right",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-left.png",
              name: "blessed-bottom-left",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-center.png",
              name: "blessed-bottom-center",
            },
          },
          {
            emoji: {
              src: "blessed-bottom-right.png",
              name: "blessed-bottom-right",
            },
          },
        ],
      },
    };
  },
};
</script>
