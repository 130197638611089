<template>
  <div
    :style="{ height: '100vh', cursor: sound.playing ? null : 'none' }"
    @click="restart"
  />
</template>

<script>
export default {
  data() {
    return {
      bufferLoaded: false,
      sound: {},
      activeJess: 0,
    };
  },
  methods: {
    restart() {
      //if (this.sound.playing) return;
      this.sound.reset();
      this.loadSound();

      if (this.activeJess === 2) {
        this.activeJess = 0;
      } else {
        this.activeJess++;
      }
    },
    loadSound() {
      Sound.loadAsBuffer(require("@/assets/mp3/scream.mp3"))
        .then((s) => {
          this.sound = s;
          this.bufferLoaded = true;

          this.sound.createBuffer().analyze(256); // recreate buffer again
          this.sound.start();
        })
        .catch((e) => console.error(e));
    },
    init() {
      Pts.quickStart(this.$el, "transparent");

      /*
       * Note: If you don't need Safari/iOS compatibility right away (as of Apr 2019)
       * A simpler method to use would be Sound.load(...) instead of Sound.loadAsBuffer(...)
       * See this demo: http://ptsjs.org/demo/edit/?name=guide.this.sound_simple
       */
      var colors = ["#f06", "#62e", "#fff", "#fe3", "#0c9"];

      const jesses = [
        require("@/assets/emojis/jess-happy-place.png"),
        require("@/assets/emojis/jess-s-cow.png"),
        require("@/assets/emojis/jess-scream.png"),
      ];

      const images = jesses.map((jess) => Img.load(jess));

      const img = Img.load(require("@/assets/emojis/jess-happy-place.png"));

      this.loadSound();

      // animation
      this.space = space.add({
        animate: (time, ftime) => {
          console.log("hello");

          if (!this.sound.playing) {
            const jess = this.activeJess === 2 ? 0 : this.activeJess + 1;
            form.image(space.pointer, images[jess]);
          }

          if (this.sound && this.sound.playing) {
            this.sound.freqDomainTo(space.size).map((t, i) => {
              //const test = form.fillOnly(colors[i % 5]).point(t, 30);
              if (i % 10 !== 0) return;
              form.image([...t, 150, 150], images[this.activeJess]);
            });
          }
        },
      });

      space.bindMouse().bindTouch().play();
    },
  },
  mounted() {
    this.timeout = setTimeout(this.init, 1000);
  },
  beforeUnmount() {
    console.log("space", this.space);
    if (this.space) this.space.dispose();
    clearTimeout(this.timeout);

    if (this.sound.playing || this.bufferLoaded) {
      this.sound.reset();
    }
  },
};
</script>

<style scoped></style>
