<template>
  <img class="emoji" :src="computedSrc" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "beam.png",
    },
  },
  computed: {
    computedSrc() {
      return require(`@/assets/emojis/${this.src}`);
    },
  },
};
</script>

<style scoped>
.emoji {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
}
</style>
