<template>
  <div />
</template>

<script>
import sketch from "./sketch.js";
export default {
  mounted() {
    this.timeout = setTimeout(() => {
      this.sketch = sketch({
        element: this.$el,
        width: this.width,
        height: this.height,
      });
    }, 1000);
  },
  unmounted() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.sketch) this.sketch.stop();
  },
};
</script>

<style scoped></style>
