<template>
  <div />
</template>

<script>
import p5 from "p5";

const s = (p) => {
  let image;
  var tileCount = 20;

  var tileWidth;
  var tileHeight;
  var shapeSize = 40;
  var newShapeSize = shapeSize;
  var shapeAngle = -90;
  var maxDist;
  var currentShape;
  var sizeMode = 0;

  p.preload = () => {
    image = p.loadImage(require("@/assets/emojis/rebeccha-nd.png"));
  };

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);
    p.imageMode(p.CENTER);
    // set the current shape to the first in the array
    currentShape = image;
    tileWidth = p.width / tileCount;
    tileHeight = p.height / tileCount;
    maxDist = p.sqrt(p.pow(p.width, 2) + p.pow(p.height, 2));
  };
  p.draw = () => {
    p.clear();

    for (var gridY = 0; gridY < tileCount; gridY++) {
      for (var gridX = 0; gridX < tileCount; gridX++) {
        var posX = tileWidth * gridX + tileWidth / 2;
        var posY = tileHeight * gridY + tileWidth / 2;

        // calculate angle between mouse position and actual position of the shape
        var angle =
          p.atan2(p.mouseY - posY, p.mouseX - posX) + shapeAngle * (p.PI / 180);

        // if (sizeMode == 0) newShapeSize = shapeSize;
        // if (sizeMode == 1)
        //   newShapeSize =
        //     shapeSize * 1.5 -
        //     map(dist(mouseX, mouseY, posX, posY), 0, 500, 5, shapeSize);
        // if (sizeMode == 2)
        //   newShapeSize = map(
        //     dist(mouseX, mouseY, posX, posY),
        //     0,
        //     500,
        //     5,
        //     shapeSize
        //   );

        p.push();
        p.translate(posX, posY);
        p.rotate(angle);
        p.noStroke();
        p.image(currentShape, 0, 0, newShapeSize, newShapeSize);
        p.pop();
      }
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };
};

export default {
  mounted() {
    this.P5 = new p5(s, this.$el);
  },
  beforeUnmount() {
    this.P5.remove();
  },
};
</script>

<style scoped></style>
