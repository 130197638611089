<template>
  <div class="frame-viewer" :style="{ background: background }">
    <div class="canvas" :style="style">
      <div class="cell" v-for="(cell, n) in cells" :key="n">
        <emoji
          v-if="cell.emoji"
          :src="cell.emoji.src"
          :title="cell.emoji.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Emoji from "@/components/Emoji";

export default {
  components: {
    Emoji,
  },
  props: {
    cells: Array,
    grid: Number,
    background: String,
  },
  computed: {
    style() {
      return {
        gridTemplateColumns: `repeat(${this.grid}, 1fr)`,
        gridTemplateRows: `repeat(${this.grid}, 1fr)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.frame-viewer {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  padding-bottom: var(--bar-height);

  .canvas {
    width: calc(100vh - var(--bar-height) * 3);
    height: calc(100vh - var(--bar-height) * 3);
    display: grid;
  }

  .cell {
    position: relative;
  }
}
</style>
