<template>
  <div class="single">
    <div :style="{ opacity: showSingle ? 1 : 0 }">
      <component
        v-if="idents[ident].component"
        :is="idents[ident].component"
        @showFooter="showFooter = $event"
      />
    </div>
    <ident v-bind="idents[ident]" v-if="!showSingle" />

    <ActionBar v-if="showSingle && showFooter" :theme="active.theme">
      <template v-slot:left>
        <span>{{ ident + 1 }} of {{ idents.length }}</span>
        {{ name }}
      </template>
      <template v-slot:right
        ><router-link :to="{ name: 'Intro' }"
          >Emotional Church</router-link
        ></template
      >
    </ActionBar>
  </div>
</template>

<script>
import { defineAsyncComponent, shallowRef } from "vue";

import Ident from "@/components/Ident";
import ActionBar from "@/components/ActionBar";

import ELP from "@/views/Singles/ELP/ELP.vue";
import Recursion from "@/views/Singles/Recursion";
import Sprite from "@/views/Singles/Sprites/Sprites.vue";
import Scream from "@/views/Singles/Scream";
import Confetti from "@/views/Singles/Confetti";
import TinyRebecca from "@/views/Singles/TinyRebecca";
import Fries from "@/views/Singles/Fries";
import EmoUna from "@/views/Singles/EmoUna";
import MatadorMix from "@/views/Singles/MatadorMix";
import HandCursor from "@/views/Singles/HandCursor";
import EnRouteCapitol from "@/views/Singles/EnRouteCapitol";
import Pong from "@/views/Singles/Pong/Pong";
import TommysFrameShop from "@/views/Singles/TommysFrameShop";
import ShowerCurtain from "@/views/Singles/ShowerCurtain";
import BlessedEvening from "@/views/Singles/BlessedEvening";
import SeriousBusiness from "@/views/Singles/SeriousBusiness";
import CalvinGuitarHero from "@/views/Singles/CalvinGuitarHero";
import Up from "@/views/Singles/Up/Up";

export default {
  components: {
    Ident,
    ActionBar,
  },
  data() {
    return {
      showSingle: false,
      showFooter: true,
      ident: this.$route.params.id ? parseInt(this.$route.params.id) : 0,
      idents: [
        {
          component: shallowRef(Sprite),
          theme: "transparent-on-dark",
          lines: [
            { text: "(Tiny)", x: "center", y: "top", fill: false },
            { text: "Tommy", x: "right", y: "top", fill: true },
            { text: "tomato", x: "right", y: "top", fill: true },
            { text: "throw", x: "left", y: "bottom", fill: false },
            { text: "(tm)", x: "left", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(Recursion),
          lines: [{ text: "Recursion", x: "center", y: "center", fill: true }],
        },
        {
          component: shallowRef(ELP),
          lines: [
            { text: "ELP", x: "left", y: "top", fill: true },
            { text: "(Emoji listing", x: "left", y: "bottom", fill: false },
            { text: "page)", x: "right", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(TinyRebecca),
          lines: [
            { text: "Tiny", x: "left", y: "top", fill: true },
            { text: "Rebecca", x: "right", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(HandCursor),
          columns: true,
          lines: [
            { text: "A hand", x: "left", y: "bottom", fill: true },
            { text: "study", x: "right", y: "bottom", fill: true },
          ],
        },
        {
          component: shallowRef(Scream),
          lines: [
            { text: "Scream", x: "left", y: "top", fill: true },
            { text: "(sound on)", x: "right", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(TommysFrameShop),
          lines: [
            { text: "Tommy's frame", x: "left", y: "center", fill: true },
            { text: "shop", x: "right", y: "bottom", fill: false },
          ],
        },
        {
          columns: true,
          component: shallowRef(EmoUna),
          theme: "transparent-on-dark",
          lines: [
            { text: "Emo", x: "left", y: "top", fill: true },
            { text: "Una", x: "left", y: "top", fill: true },
            { text: "DVD", x: "right", y: "bottom", fill: true },
          ],
        },
        {
          component: shallowRef(MatadorMix),
          lines: [
            { text: "Matador", x: "left", y: "center", fill: true },
            { text: "mix", x: "right", y: "center", fill: false },
          ],
        },
        {
          component: shallowRef(BlessedEvening),
          lines: [
            { text: "Blessed", x: "center", y: "top", fill: true },
            { text: "evening", x: "center", y: "bottom", fill: false },
          ],
        },

        {
          component: shallowRef(EnRouteCapitol),
          theme: "transparent-on-dark",
          lines: [
            { text: "En route", x: "center", y: "top", fill: false },
            { text: "to", x: "center", y: "center", fill: true },
            { text: "the capitol", x: "center", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(SeriousBusiness),
          lines: [
            { text: "Serious", x: "center", y: "bottom", fill: true },
            { text: "Business", x: "left", y: "bottom", fill: true },
          ],
        },
        {
          component: shallowRef(Up),
          lines: [{ text: "Up", x: "right", y: "right", fill: true }],
        },
        {
          component: shallowRef(Fries),
          theme: "transparent-on-light",
          lines: [
            { text: "Fries in", x: "center", y: "center", fill: true },
            { text: "Denver", x: "bottom", y: "right", fill: true },
          ],
        },
        {
          component: shallowRef(ShowerCurtain),
          theme: "transparent-on-dark",
          lines: [
            { text: "Bus", x: "left", y: "top", fill: false },
            { text: "shower", x: "right", y: "top", fill: true },
            { text: "curtain", x: "right", y: "bottom", fill: false },
          ],
        },
        {
          component: shallowRef(CalvinGuitarHero),
          lines: [
            { text: "Calvin", x: "center", y: "center", fill: true },
            { text: "guitar", x: "center", y: "center", fill: true },
            { text: "hero", x: "center", y: "center", fill: true },
          ],
        },
        {
          component: shallowRef(Pong),
          theme: "transparent-on-dark",
          lines: [{ text: "The Arcade", x: "center", y: "center", fill: true }],
        },
      ],
    };
  },
  computed: {
    active() {
      return this.idents[this.ident];
    },
    name() {
      const lines = this.active.lines.map((line) => line.text);
      return lines.join(" ");
    },
  },
  watch: {
    ident() {
      this.$router.replace({ name: "Single", params: { id: this.ident } });
    },
  },
  methods: {
    reset() {
      this.showSingle = false;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.showSingle = true;
      }, 1000);
    },
    onKeydown(e) {
      switch (e.key) {
        case "ArrowLeft":
          if (this.ident === 0) {
            this.ident = this.idents.length - 1;
            this.reset();
            return;
          }
          this.ident--;
          this.reset();
          break;
        case "ArrowRight":
          if (this.ident === this.idents.length - 1) {
            this.ident = 0;
            this.reset();
            return;
          }
          this.ident++;
          this.reset();
          break;
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", this.onKeydown);
    this.reset();
  },
  umnounted() {
    document.removeEventListener("keydown", this.onKeydown);
  },
};
</script>
