<template>
  <div />
</template>

<script>
import p5 from "p5";

const s = (p) => {
  const candy = [];
  let images;

  class Candy {
    constructor({ x, y }) {
      this.size = Math.random() * 20 + 16;
      this.angle = Math.random();
      this.dir = Math.random() > 0.5 ? 1 : -1;
      this.x = x;
      this.y = y;
      this.img = p.random(images);
    }

    render() {
      if (this.y < window.innerHeight) {
        const oneDegree = Math.PI / 180;
        this.y += 50 / this.size + Math.random();
        this.x += Math.random() - Math.random();
        this.angle += this.dir * (Math.random() * oneDegree + oneDegree);

        p.push();
        p.imageMode(p.CENTER);
        p.translate(this.x + this.size / 2, this.y + this.size / 2);
        p.rotate(this.angle);
        p.image(this.img, 0, 0, this.size, this.size);
        p.pop();
      }
    }
  }

  p.preload = () => {
    images = [
      p.loadImage(require("@/assets/emojis/labre-larve.png")),
      p.loadImage(require("@/assets/emojis/roed-vingummi.png")),
      p.loadImage(require("@/assets/emojis/kedelig-lakrids.png")),
      p.loadImage(require("@/assets/emojis/dragon-egg.png")),
    ];
  };

  p.setup = () => {
    const canvas = p.createCanvas(p.windowWidth, p.windowHeight);
  };

  p.draw = () => {
    p.background("255");

    // remove confetti if reaching the bottom or too many
    if (candy.length > 1000 || (candy.length > 0 && candy[0].y > p.height))
      candy.shift();

    // add a confetti every second
    if (Math.floor(p.time % 1000) > 980) {
      candy.push(new Candy({ x: p.mouseX, y: p.mouseY }));
    }

    // render the confetti
    candy.forEach((c) => c.render());
  };

  p.mouseMoved = () => {
    candy.push(new Candy({ x: p.mouseX, y: p.mouseY }));
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
  };
};

export default {
  mounted() {
    this.P5 = new p5(s, this.$el);
  },
  beforeUnmount() {
    this.P5.remove();
  },
};
</script>

<style scoped></style>
