<template>
  <div />
</template>

<script>
import sketch from "./sprites.js";
export default {
  mounted() {
    this.sketch = sketch({
      element: this.$el,
      width: this.width,
      height: this.height,
    });
  },
  unmounted() {
    this.sketch.stop();
  },
};
</script>

<style scoped></style>
