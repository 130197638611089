import { createStore } from "vuex";

export default createStore({
  state: {
    authors: [
      {
        name: "Tommy Campbell",
        avatar: require("@/assets/emojis/tommy-fuggedaboutit.png"),
      },
      { name: "Una Savic", avatar: require("@/assets/emojis/una-happy.png") },
      {
        name: "Jakob Henner",
        avatar: require("@/assets/emojis/jacob-happy-place.gif"),
      },
      {
        name: "Rebecca Duff-Smith",
        avatar: require("@/assets/emojis/rebecca-deal-with-it.png"),
      },
      {
        name: "Carola Cassaro",
        avatar: require("@/assets/emojis/carola-listening-to-a-captivating-story-part-1.png"),
      },
      {
        name: "Paul Samples",
        avatar: require("@/assets/emojis/paul-texas-taxes.png"),
      },
      {
        name: "Jess Xuan",
        avatar: require("@/assets/emojis/jess-happy-place.png"),
      },
      {
        name: "Dever Thomas",
        avatar: require("@/assets/emojis/blue-judging.png"),
      },
      {
        name: "Brock Boddie",
        avatar: require("@/assets/emojis/brock-the-original.png"),
      },
      {
        name: "Isabella Holm",
        avatar: require("@/assets/img/T03RN8ZFA-U01C656645R-e01939708703-512.png"),
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
  getters: {
    getAuthor: (state) => (name) =>
      state.authors.find((author) => author.name === name),
  },
});
