<template>
  <div class="shop">
    <div
      class="tool"
      :style="style"
      @mousedown="onMousedown"
      @mousemove="onMousemove"
      @mouseup="onMouseup"
    >
      <div
        class="cell"
        v-for="(cell, n) in cells"
        @click="onClick(cell)"
        :data-index="n"
        :key="n"
        :style="{ background: cell.selected ? 'black' : null }"
      >
        <emoji
          v-if="cell.emoji"
          :src="cell.emoji.src"
          :title="cell.emoji.name"
        />
      </div>
    </div>
    <action-bar above theme="transparent-on-light">
      <input type="range" min="4" max="40" v-model="grid" />
      <span @click="showEmojiPicker = true">+ EMOJI</span>
      <span @click="copyToSlack">Copy to Slack</span>
      <!-- <span @click="copyCode">Code</span> -->
    </action-bar>
    <emoji-picker
      v-if="showEmojiPicker"
      @close="showEmojiPicker = false"
      @select="onSelected"
    />
  </div>
</template>

<script>
import ActionBar from "@/components/ActionBar";
import EmojiPicker from "@/components/EmojiPicker";
import Emoji from "@/components/Emoji";

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const splitEvery = (n, xs, y = []) =>
  xs.length === 0 ? y : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]));

export default {
  components: {
    ActionBar,
    EmojiPicker,
    Emoji,
  },
  data() {
    return {
      grid: 10,
      cells: [],
      drag: false,
      showEmojiPicker: false,
    };
  },
  methods: {
    onSelected(emoji) {
      const selected = this.cells.filter((cell) => cell.selected);

      selected.forEach((cell) => {
        cell.emoji = { src: emoji.src, name: emoji.name };
        cell.selected = false;
      });
      this.showEmojiPicker = false;
    },
    onClick(cell) {
      if (this.drag) return;
      cell.selected = !cell.selected;
    },
    onMousedown() {
      this.drag = true;
    },
    onMousemove(e) {
      if (!this.drag) return;
      console.log(e.target);

      if (!e.target) return;

      const n = e.target.dataset.index;
      if (typeof n === "undefined") return;

      this.cells[n].selected = true;
    },
    onMouseup() {
      this.drag = false;
    },
    updateCells() {
      const count = this.grid * this.grid;
      this.cells = [];
      for (let i = 0; i < count; i++) {
        this.cells.push({
          emoji: null,
          selected: false,
        });
      }
    },
    async copyCode() {
      const cells = this.cells.map((cell) => {
        delete cell.selected;
        return cell;
      });

      const output = {
        grid: this.grid,
        cells,
      };

      await navigator.clipboard.writeText(JSON.stringify(output));
    },
    async copyToSlack() {
      const text = this.cells.map((cell) =>
        cell.emoji ? `:${cell.emoji.name}:` : ":e:"
      );
      const rows = splitEvery(parseInt(this.grid), text); //sliceIntoChunks(text, this.grid);

      console.log(rows, "ROWs");

      const lines = rows.map((row) => row.join(""));

      const output = lines.join("\n");

      await navigator.clipboard.writeText(output);
      console.log("rows", output);
    },
  },
  watch: {
    grid: "updateCells",
  },
  computed: {
    style() {
      return {
        gridTemplateColumns: `repeat(${this.grid}, 1fr)`,
        gridTemplateRows: `repeat(${this.grid}, 1fr)`,
      };
    },
  },
  mounted() {
    this.updateCells();
  },
};
</script>

<style lang="scss" scoped>
.shop {
  width: 100vw;
  height: calc(100vh);
  display: grid;
  place-items: center;
  padding-bottom: var(--bar-height);

  .tool {
    width: calc(100vh - var(--bar-height) * 3);
    height: calc(100vh - var(--bar-height) * 3);
    background: black;
    display: grid;
    outline: 3px solid black;
    gap: 3px;
  }

  .cell {
    background: white;
    position: relative;
  }
}
</style>
