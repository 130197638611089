import { beep1, beep2, beep3 } from "./sounds";
import data from "@/assets/emojis.json";

// Global Variables
var DIRECTION = {
  IDLE: 0,
  UP: 1,
  DOWN: 2,
  LEFT: 3,
  RIGHT: 4,
};

var rounds = [5, 5, 3, 3, 2];

class Paddle {
  constructor(canvas, side) {
    this.width = 88;
    this.height = 256;
    this.x = side === "left" ? 150 : canvas.width - 150;
    this.y = canvas.height / 2 - 35;
    this.score = 0;
    this.move = DIRECTION.IDLE;
    this.speed = 10;
  }
}

class Ball {
  constructor(canvas, incrementedSpeed) {
    this.width = 88;
    this.height = 88;
    this.x = canvas.width / 2 - 44;
    this.y = canvas.height / 2 - 44;
    this.moveX = DIRECTION.IDLE;
    this.moveY = DIRECTION.IDLE;
    this.speed = incrementedSpeed || 10;
  }
}

class Pong {
  constructor(el) {
    console.log("init");
    this.raf = null;
    this.canvas = el;
    this.context = this.canvas.getContext("2d");

    this.canvas.width = window.innerWidth * 1.75;
    this.canvas.height = window.innerHeight * 1.75;

    this.canvas.style.width = this.canvas.width / 2 + "px";
    this.canvas.style.height = this.canvas.height / 2 + "px";

    this.player = new Paddle(this.canvas, "left");
    this.paddle = new Paddle(this.canvas, "right");
    this.ball = new Ball(this.canvas);

    this.paddle.speed = 8;
    this.running = this.over = false;
    this.turn = this.paddle;
    this.timer = this.round = 0;
    this.color = "black";

    this.emoji = 0;
    this.emojis = data
      .map((d) => {
        const src = require(`@/assets/emojis/${d.src}`);
        const img = new Image(88, 88); // Using optional size for img
        img.src = src;
        return img;
      })
      .sort(() => 0.5 - Math.random());

    this.menu();
    this.listen();
  }

  endGameMenu(text) {
    // Change the canvas font size and color
    this.context.font = "38px Untitled";
    this.context.fillStyle = this.color;

    // Draw the rectangle behind the 'Press any key to begin' text.
    this.context.fillRect(
      this.canvas.width / 2 - 350,
      this.canvas.height / 2 - 48,
      700,
      100
    );

    // Change the canvas color;
    this.context.fillStyle = "white";

    // Draw the end game menu text ('Game Over' and 'Winner')
    this.context.fillText(
      text,
      this.canvas.width / 2,
      this.canvas.height / 2 + 15
    );

    setTimeout(() => {
      //Pong = Object.assign({}, Game);
      this.initialize(this.canvas);
    }, 3000);
  }

  menu() {
    // Draw all the Pong objects in their current state
    this.draw();

    // Change the canvas font size and color
    this.context.font = "38px Untitled";
    this.context.fillStyle = this.color;

    // Draw the rectangle behind the 'Press any key to begin' text.
    this.context.fillRect(
      this.canvas.width / 2 - 350,
      this.canvas.height / 2 - 48,
      700,
      100
    );

    // Change the canvas color;
    this.context.fillStyle = "white";

    // Draw the 'press any key to begin' text
    this.context.fillText(
      "PRESS ANY KEY",
      this.canvas.width / 2,
      this.canvas.height / 2 + 15
    );
  }

  nextEmoji() {
    if (this.emoji === this.emojis.length - 1) this.emoji = -1;
    this.emoji++;
  }

  // Update all objects (move the player, paddle, ball, increment the score, etc.)
  update() {
    if (!this.over) {
      // If the ball collides with the bound limits - correct the x and y coords.
      if (this.ball.x <= 0) this._resetTurn(this.paddle, this.player);
      if (this.ball.x >= this.canvas.width - this.ball.width)
        this._resetTurn(this.player, this.paddle);
      if (this.ball.y <= 0) this.ball.moveY = DIRECTION.DOWN;
      if (this.ball.y >= this.canvas.height - this.ball.height)
        this.ball.moveY = DIRECTION.UP;

      // Move player if they player.move value was updated by a keyboard event
      if (this.player.move === DIRECTION.UP) this.player.y -= this.player.speed;
      else if (this.player.move === DIRECTION.DOWN)
        this.player.y += this.player.speed;

      // On new serve (start of each turn) move the ball to the correct side
      // and randomize the direction to add some challenge.
      if (this._turnDelayIsOver() && this.turn) {
        this.ball.moveX =
          this.turn === this.player ? DIRECTION.LEFT : DIRECTION.RIGHT;
        this.ball.moveY = [DIRECTION.UP, DIRECTION.DOWN][
          Math.round(Math.random())
        ];
        this.ball.y =
          Math.floor(Math.random() * this.canvas.height - 200) + 200;
        this.turn = null;
      }

      // If the player collides with the bound limits, update the x and y coords.
      if (this.player.y <= 0) this.player.y = 0;
      else if (this.player.y >= this.canvas.height - this.player.height)
        this.player.y = this.canvas.height - this.player.height;

      // Move ball in intended direction based on moveY and moveX values
      if (this.ball.moveY === DIRECTION.UP)
        this.ball.y -= this.ball.speed / 1.5;
      else if (this.ball.moveY === DIRECTION.DOWN)
        this.ball.y += this.ball.speed / 1.5;
      if (this.ball.moveX === DIRECTION.LEFT) this.ball.x -= this.ball.speed;
      else if (this.ball.moveX === DIRECTION.RIGHT)
        this.ball.x += this.ball.speed;

      // Handle paddle (AI) UP and DOWN movement
      if (this.paddle.y > this.ball.y - this.paddle.height / 2) {
        if (this.ball.moveX === DIRECTION.RIGHT)
          this.paddle.y -= this.paddle.speed / 1.5;
        else this.paddle.y -= this.paddle.speed / 4;
      }
      if (this.paddle.y < this.ball.y - this.paddle.height / 2) {
        if (this.ball.moveX === DIRECTION.RIGHT)
          this.paddle.y += this.paddle.speed / 1.5;
        else this.paddle.y += this.paddle.speed / 4;
      }

      // Handle paddle (AI) wall collision
      if (this.paddle.y >= this.canvas.height - this.paddle.height)
        this.paddle.y = this.canvas.height - this.paddle.height;
      else if (this.paddle.y <= 0) this.paddle.y = 0;

      // Handle Player-Ball collisions
      if (
        this.ball.x - this.ball.width <= this.player.x &&
        this.ball.x >= this.player.x - this.player.width
      ) {
        if (
          this.ball.y <= this.player.y + this.player.height &&
          this.ball.y + this.ball.height >= this.player.y
        ) {
          this.ball.x = this.player.x + this.ball.width;
          this.ball.moveX = DIRECTION.RIGHT;

          beep1.play();
          this.nextEmoji();
        }
      }

      // Handle paddle-ball collision
      if (
        this.ball.x - this.ball.width <= this.paddle.x &&
        this.ball.x >= this.paddle.x - this.paddle.width
      ) {
        if (
          this.ball.y <= this.paddle.y + this.paddle.height &&
          this.ball.y + this.ball.height >= this.paddle.y
        ) {
          this.ball.x = this.paddle.x - this.ball.width;
          this.ball.moveX = DIRECTION.LEFT;

          beep1.play();
          this.nextEmoji();
        }
      }
    }

    // Handle the end of round transition
    // Check to see if the player won the round.
    if (this.player.score === rounds[this.round]) {
      // Check to see if there are any more rounds/levels left and display the victory screen if
      // there are not.
      if (!rounds[this.round + 1]) {
        this.over = true;
        setTimeout(function () {
          this.endGameMenu("WINNER");
        }, 1000);
      } else {
        // If there is another round, reset all the values and increment the round number.
        //this.color = this._generateRoundColor();
        this.player.score = this.paddle.score = 0;
        this.player.speed += 0.5;
        this.paddle.speed += 1;
        this.ball.speed += 1;
        this.round += 1;

        beep3.play();
      }
    }
    // Check to see if the paddle/AI has won the round.
    else if (this.paddle.score === rounds[this.round]) {
      this.over = true;
      setTimeout(function () {
        this.endGameMenu("GAME OVER");
      }, 1000);
    }
  }

  // Draw the objects to the canvas element
  draw() {
    // Clear the Canvas
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Set the fill style to black
    this.context.fillStyle = this.color;

    // Draw the background
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    // Set the fill style to white (For the paddles and the ball)
    this.context.fillStyle = "white";

    // Draw the Player
    this.context.fillRect(
      this.player.x,
      this.player.y,
      this.player.width,
      this.player.height
    );

    // Draw the Paddle
    this.context.fillRect(
      this.paddle.x,
      this.paddle.y,
      this.paddle.width,
      this.paddle.height
    );

    // Draw the Ball
    if (this._turnDelayIsOver()) {
      // this.context.fillRect(
      //   this.ball.x,
      //   this.ball.y,
      //   this.ball.width,
      //   this.ball.height
      // );
      this.context.drawImage(
        this.emojis[this.emoji],
        this.ball.x,
        this.ball.y,
        this.ball.width,
        this.ball.height
      );
    }

    // Draw the net (Line in the middle)
    this.context.beginPath();
    this.context.setLineDash([7, 15]);
    this.context.moveTo(this.canvas.width / 2, this.canvas.height - 140);
    this.context.lineTo(this.canvas.width / 2, 140);
    this.context.lineWidth = 3;
    this.context.strokeStyle = "white";
    this.context.stroke();

    // Set the default canvas font and align it to the center
    this.context.font = "38px Untitled";
    this.context.textAlign = "center";

    // Draw the players score (left)
    this.context.fillText(
      this.player.score.toString(),
      this.canvas.width / 2 - 300,
      200
    );

    // Draw the paddles score (right)
    this.context.fillText(
      this.paddle.score.toString(),
      this.canvas.width / 2 + 300,
      200
    );

    // Change the font size for the center score text
    this.context.font = "38px Untitled";

    // Draw the winning score (center)
    this.context.fillText(
      "ROUND " + (this.round + 1),
      this.canvas.width / 2,
      35
    );

    // Change the font size for the center score value
    this.context.font = "38px Untitled";

    // Draw the current round number
    // this.context.fillText(
    //   rounds[this.round] ? rounds[this.round] : rounds[this.round - 1],
    //   this.canvas.width / 2,
    //   100
    // );
  }

  loop() {
    this.update();
    this.draw();

    // If the game is not over, draw the next frame.
    if (!this.over) this.raf = requestAnimationFrame(this.loop.bind(this));
  }

  keydown(key) {
    // Handle the 'Press any key to begin' function and start the game.
    if (this.running === false) {
      this.running = true;
      this.raf = window.requestAnimationFrame(this.loop.bind(this));
    }

    // Handle up arrow and w key events
    if (key.keyCode === 38 || key.keyCode === 87)
      this.player.move = DIRECTION.UP;

    // Handle down arrow and s key events
    if (key.keyCode === 40 || key.keyCode === 83)
      this.player.move = DIRECTION.DOWN;
  }
  keyup(key) {
    this.player.move = DIRECTION.IDLE;
  }

  listen() {
    this.boundKeydown = (e) => this.keydown(e);
    this.boundKeyup = (e) => this.keyup(e);

    document.addEventListener("keydown", this.boundKeydown);

    // Stop the player from moving when there are no keys being pressed.
    document.addEventListener("keyup", this.boundKeyup);
  }

  stop() {
    this.over = true;
    if (this.raf) cancelAnimationFrame(this.raf);

    document.removeEventListener("keydown", this.boundKeydown);
    document.removeEventListener("keyup", this.boundKeyup);
  }

  // Reset the ball location, the player turns and set a delay before the next round begins.
  _resetTurn(victor, loser) {
    this.ball = new Ball(this.canvas, this.ball.speed);
    this.turn = loser;
    this.timer = new Date().getTime();

    victor.score++;
    beep2.play();
  }

  // Wait for a delay to have passed after each turn.
  _turnDelayIsOver() {
    return new Date().getTime() - this.timer >= 1000;
  }

  // Select a random color as the background of each level/round.
  _generateRoundColor() {
    var newColor = colors[Math.floor(Math.random() * colors.length)];
    if (newColor === this.color) return this._generateRoundColor();
    return newColor;
  }
}

export default Pong;
